<template>
  <div class="container">
    <div class="row" v-if="spinning">
      <div class="col-md-12 text-center">
        <span style="color: red">Sending Documents...Please wait</span>
      </div>
    </div>
    <div class="row">
      <div class="sidebar col-sm-3">
        <div class="logo-container">
          <img width="230" height="65" class="wizard-logo-image" style="margin-top:15px;border-radius:7px;"
               src="/static/img/ascent_logo_dealer.png">
        </div>
        <h2>Steps:</h2>
        <ul>
          <li v-for="({message, sid} ) in steps" @click="setCurrentStep(sid)"
              :class="{ active: currentStep === sid}" :key="message">
            {{ message }}
          </li>
        </ul>
      </div>
      <div class="col-sm-2"></div>
      <div class="col-sm-7">

        <!-- Step 1 Agent Info -->
        <div v-if="currentStep === 'AIN'">
          <ValidationObserver ref="AIN">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Agent Information</h3>
              </div>
            </div>

            <div class="div-group">
              <h4>Please enter the agent information details below</h4>

              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agentName"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input label="Agent Name" name="agentName"
                              :error="failed ? 'The agent name is required': null"
                              :hasSuccess="passed"
                              placeholder="Agent Name" v-model="agentName"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agency"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input label="Agency" name="agency"
                              :error="failed ? 'The agency is required': null"
                              :hasSuccess="passed"
                              placeholder="Agency" v-model="agency"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agent_address1"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input label="Address1" name="address1"
                              :error="failed ? 'The address1 field is required': null"
                              :hasSuccess="passed"
                              placeholder="Address 1" v-model="agentAddress1"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <fg-input label="Address2" name="address2"
                            placeholder="Address 2" v-model="agentAddress2"/>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agent_city"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input label="City" name="city"
                              :error="failed ? 'The city field is required': null"
                              :hasSuccess="passed"
                              placeholder="Address 1" v-model="agentCity"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>
                    State
                    <select @change="stateSelected('agent')" class="form-control" v-model="agentState"
                            name="agentState">
                      <option value="" disabled>Select a state</option>
                      <option v-for="state in states" :key="state.abbreviation"
                              :value="state.abbreviation">
                        {{ state.name }}
                      </option>
                    </select>
                  </label>
                  <div v-if="agentStateError" class="text-danger invalid-feedback"
                       style="display: block;">The state field is required
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agentZip"
                    rules="required|min:5"
                    v-slot="{ passed, failed }">
                    <fg-input
                      name="agentZip"
                      label="Zip Code"
                      :error="failed ? 'The zip is required': null"
                      :hasSuccess="passed"
                      placeholder="Zip Code"
                      v-model="agentZip"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="agentEmail"
                    rules="required|email"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The agent email is required': null"
                      name="agentEmail"
                      :hasSuccess="passed"
                      label="Agent Email"
                      placeholder="Agent Email"
                      v-model="agentEmail"/>
                  </ValidationProvider>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 2 Dealer Info -->
        <div v-if="currentStep === 'DIN'">
          <ValidationObserver ref="DIN">
            <div class="col-sm-12 text-center">
              <h3>Dealership Information</h3>
            </div>
            <div class="div-group">
              <h4>Please enter the dealership detail information</h4>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="dealerName"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input name="dealerName"
                              label="Dealership Name"
                              :error="failed ? 'The Dealership name is required': null"
                              :hasSuccess="passed"
                              placeholder="Dealership Name"
                              v-model="dealerName"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="dealerAddress1"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input label="Dealership Address1" name="address1"
                              :error="failed ? 'The dealership address1 field is required': null"
                              :hasSuccess="passed"
                              placeholder="Dealership Address 1" v-model="dealerAddress1"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <fg-input label="Dealership Address2" name="address2"
                            placeholder="Dealership Address 2" v-model="dealerAddress2"/>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <ValidationProvider
                    name="dealerCity"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input
                      label="Dealership City"
                      name="dealerCity"
                      :error="failed ? 'The dealership city is required': null"
                      :hasSuccess="passed"
                      placeholder="Dealership City"
                      v-model="dealerCity"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <label>
                    Dealership State
                    <select class="form-control" v-model="dealerState"
                            @change="stateSelected('dealer')"
                            name="dealerState">
                      <option value="" disabled>Select a state</option>
                      <option v-for="state in states" :key="state.abbreviation"
                              :value="state.abbreviation">
                        {{ state.name }}
                      </option>
                    </select>
                  </label>
                  <br>
                  <div v-if="dealerStateError" class="text-danger invalid-feedback"
                       style="display: block;">The state field is required
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerZip"
                    rules="required|min:5"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The Dealership zip is required': null"
                      name="dealerZip"
                      :hasSuccess="passed"
                      label="Dealership Zip"
                      placeholder="Dealership Zip"
                      v-model="dealerZip"/>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 3 Repair Center -->
        <div v-if="currentStep === 'PRC'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>On Site Repair Center</h3>
            </div>
          </div>

          <div class="div-group">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h4>Does this dealer have an onsite repair center?</h4>
                <select class="form-control" v-model="haveOnSiteRepairCenter">
                  <option disabled>On Site Repair Center</option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="haveOnSiteRepairCenter === false">
              <div class="col-sm-12 text-center">
                <h4>Does this dealer have a preferred repair center?</h4>
                <select class="form-control" v-model="havePreferredRepair">
                  <option disabled>On Site Repair Center</option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </div>
            </div>
            <div class="row" v-if="havePreferredRepair">
              <div class="col-sm-12 text-center">
                <fg-input
                  name="preferredRepairCenter"
                  label="Preferred Repair Center Name"
                  placeholder="Repair Center Name"
                  v-model="preferredRepairCenter"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>
        </div>

        <!-- Step 4 Dealer Contact Info -->
        <div v-if="currentStep === 'ASI'">
          <ValidationObserver ref="ASI">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Authorized Signer Information</h3>
              </div>
            </div>

            <div class="div-group">
              <h4>Please enter the authorized signer contact information</h4>

              <div class="row">

                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerFirstName"
                    rules="required"
                    v-slot="{ passed, failed }">
                    <fg-input
                      name="dealerFirstName"
                      :error="failed ? 'The first name is required': null"
                      :hasSuccess="passed"
                      label="First Name"
                      placeholder="First"
                      v-model="dealerFirstName"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerLastName"
                    rules="required"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The last name is required': null"
                      :hasSuccess="passed"
                      label="Last Name"
                      name="dealerLastName"
                      placeholder="Last"
                      v-model="dealerLastName"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerPhone"
                    rules="required|phone"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The phone is required': null"
                      name="dealerPhone"
                      :hasSuccess="passed"
                      label="Phone Number"
                      placeholder="Phone Number"
                      v-model="dealerPhone"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerEmail"
                    rules="required|email"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The email is required': null"
                      name="dealerEmail"
                      :hasSuccess="passed"
                      label="Email"
                      placeholder="Email"
                      v-model="dealerEmail"/>
                  </ValidationProvider>
                </div>
                <div class="col">
                  All documents will be sent to this e-mail address
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- STEP 5 Monthly Remit Contact Information -->
        <div v-if="currentStep === 'RIN'">
          <ValidationObserver ref="RIN">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Remitter Information</h3>
              </div>
            </div>

            <div class="div-group">

              <h4>
                Please enter the information for the person who will be doing the remitting.
                This is usually the person who will be remitting the contracts.
              </h4>

              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="dealerRemitFirst"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The remitter first name is required': null"
                      :hasSuccess="passed"
                      label="Remitter First Name"
                      name="monthlyRemitFirst"
                      placeholder="Remitter First Name"
                      v-model="monthlyRemitFirst"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="monthlyRemitLast"
                    rules="required|min:2"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The remitter last name is required': null"
                      :hasSuccess="passed"
                      label="Remitter Last Name"
                      name="monthlyRemitLast"
                      placeholder="Remitter Last Name"
                      v-model="monthlyRemitLast"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <ValidationProvider
                    name="monthlyRemitEmail"
                    rules="required|email"
                    v-slot="{ passed, failed }">
                    <fg-input
                      :error="failed ? 'The email of the remitter is required': null"
                      :hasSuccess="passed"
                      label="Email of Remitter Contact"
                      name="monthlyRemitEmail"
                      placeholder="Remitter Email Contact"
                      v-model="paymentRemitEmail"/>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <label>
                    Select a payment Option:
                    <select name="paymentRemitMethod"
                            class="form-control"
                            v-model="paymentRemitMethod">
                      <option disabled>Please Select a payment option...</option>
                      <option value="check">Check</option>
                      <option value="ach">ACH</option>
                    </select>
                  </label>
                  <div v-if="paymentRemitMethodError">
                    <span style="display: block;" class="text-danger invalid-feedback">The payment remit is required</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- STEP 6 Ascent Menu Integration -->
        <div v-if="currentStep === 'MIN'">
          <ValidationObserver ref="MIN">
            <div class="col-sm-12 text-center">
              <h3>Menu Integration</h3>
            </div>
            <div class="div-group">
              <h4>Please select, if you need menu integration and then select from the list that
                will appear</h4>
              <div class="row">
                <div class="col-sm-12">
                  <label>Do you need Ascent product integration into a menu?
                    <select class="form-control" v-model="needMenuIntegration">
                      <option disabled selected>Select an Option</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </label>
                  <div v-if="menuIntegrationError">
                    <span style="display: block;" class="text-danger invalid-feedback">You must select yes or no</span>
                  </div>
                </div>
              </div>
              <div class="row" v-if="needMenuIntegration === 'true'">
                <div class="col-sm-12">
                  <b-row class="my-3">
                    <b-col>
                      <!-- Select All Checkbox -->
                      <b-form-checkbox v-model="selectAll" @change="toggleSelectAll">
                        Select All
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-row v-for="item in options" :key="item" class="mb-2">
                    <b-col>
                      <b-form-checkbox :value="item" v-model="selectedOptions">
                        {{ item.label }}
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 7 Additional Information -->
        <div v-if="currentStep === 'SPP'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>SPP ( Service Payment Plan ) </h3>
            </div>
          </div>
          <div class="row div-group">
            <div class="col-sm-12 text-center">
              <h4>Would you like to be integrated with SPP?</h4>
            </div>
            <div class="col-sm-12 text-center">
              <label>
                <select class="form-control" v-model="needSPP">
                  <option disabled>Would you like to be integrated with SPP?</option>
                  <option :value="true">Yes</option>
                  <option :value="false">No</option>
                </select>
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>
        </div>

        <div v-if="currentStep === 'LEN'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>Lenders</h3>
            </div>
          </div>
          <div class="div-group">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group" id="lenders">
                  <div v-for="(lender, index) of lenders" :key="index">
                    <fg-input
                      name="lenderName"
                      placeholder="Lender name"
                      label="Lender Name"
                      v-model="lenders[index]"></fg-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>
        </div>

        <!-- Step 8 PCRS Login -->
        <div v-if="currentStep === 'PUC'">
          <ValidationObserver ref="PUC">
            <div class="col-sm-12 text-center">
              <h3>PCRS User Creation</h3>
            </div>

            <div class="div-group">

              <div class="col-sm-12 ">
                <h4>Please create as many users as you need</h4>
              </div>

              <div class="row">
                <div class="col-sm-4">
                  <ValidationProvider
                    name="userEmail"
                    label="User Email"
                    rules="required|email"
                    v-slot="{ passed, failed }">
                    <fg-input
                      name="userEmail"
                      :error="failed ? 'Must be a valid email address': null"
                      :hasSuccess="passed"
                      placeholder="User Email"
                      label="User Email"
                      v-model="newUser.email"></fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider
                    name="userFirstName"
                    rules="min:2"
                    label="User First Name"
                    v-slot="{ passed, failed }">
                    <fg-input
                      name="userFirstname"
                      :error="failed ? 'First name must be 2 characters or more': null"
                      :hasSuccess="passed"
                      label="User First Name"
                      placeholder="User First Name"
                      v-model="newUser.first_name"></fg-input>
                  </ValidationProvider>
                </div>
                <div class="col-sm-4">
                  <ValidationProvider
                    name="userLastName"
                    rules="min:2"
                    label="User Last Name"
                    v-slot="{ passed, failed }">
                    <fg-input
                      rules="min:2"
                      name="userLastName"
                      label="User Last Name"
                      placeholder="User Last Name"
                      :error="failed ? 'Last name must be 2 characters or more': null"
                      :hasSuccess="passed"
                      v-model="newUser.last_name"></fg-input>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <label>Select a user role
                    <select class="form-control" v-model="newUser.user_role">
                      <option disabled>Select One</option>
                      <option value="management">Management</option>
                      <option value="business_office">Business Office</option>
                      <option value="f_i_office">F&I Office</option>
                      <option value="service">Service</option>
                    </select>
                  </label>
                  <div v-if="newUserRoleError">
                    <span style="display: block;" class="text-danger invalid-feedback">A user role must be selected</span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 ">
                  <l-button @click="addUser" type="primary" round>Add</l-button>
                </div>
                <div class="container border-gray">
                  <div slot="header">
                    <h4 class="card-title">Users to be created</h4>
                    <p class="card-category">The list of users that will be created in PCRS</p>
                  </div>
                  <div class="table-responsive table-full-width">
                    <el-table class="table-striped" :data="users">
                      <el-table-column label="First" prop="first_name"></el-table-column>
                      <el-table-column label="Last" prop="last_name"></el-table-column>
                      <el-table-column label="Email" prop="email"></el-table-column>
                      <el-table-column label="Role" prop="user_role"></el-table-column>
                      <el-table-column label="Action">
                        <template slot-scope="{ $index }">
                          <l-button class="trash-button" type="error" @click="removeUser($index)"></l-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                </div>

              </div>

              <div class="row">
                <div class="col-sm-12">
                  <l-button type="primary" @click="previousStep" round>Previous</l-button>
                  <l-button type="primary" @click="nextStep" round>Next</l-button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 9 Docusign Documents -->
        <div v-if="currentStep === 'ZOS'">
          <ValidationObserver ref="ZOS">
            <div class="col-sm-12 text-center">
              <h3>Zoho Sign</h3>
            </div>
            <div class="row div-group">
              <h4>You'll automatically receive emails from Zoho Sign to the email addresses listed below.  No action is necessary.</h4>
              <div class="col-sm-12">
                <el-table class="table-striped" :data="viewUsers">
                  <el-table-column label="First Name" prop="first"></el-table-column>
                  <el-table-column label="Last Name" prop="last"></el-table-column>
                  <el-table-column label="Email" prop="email"></el-table-column>
                </el-table>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 10 Documentation -->

        <!-- STEP 11 Pinnacle or Elevate -->
        <div v-if="currentStep === 'EWA'">
          <ValidationObserver ref="EWA">
            <div class="col-sm-12 text-center">
              <h3>Reinsured or Walk Away</h3>
            </div>
            <div class="row div-group">
              <h4>Please select whether the dealer is<br/>Reinsured (Elevate) or is Walk Away (Pinnacle) </h4>
              <div class="col-sm-12">
                <label>Reinsured or Walk Away
                  <select class="form-control" v-model="pinnacleOrElevate">
                    <option disabled selected>Select an Option</option>
                    <option value="elevate">Reinsured</option>
                    <option value="pinnacle">Walk Away</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Pinnacle Product Selection -->
        <div v-if="currentStep === 'PPS'">
          <ValidationObserver ref="PPS">

            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Pinnacle Product Selection</h3>
                <h4>Please select the products the dealer will be selling</h4>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="div-group" id="products">
                  <div v-for="product of vendorProducts(['Pinnacle'])" :key="product.id" class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        :value="product"
                        :disabled="requiredProducts.includes(product.id)"
                        v-model="pinnacleProductsSelected"
                      >
                      {{ product.label }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Ascension Product Selection</h3>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="div-group" id="products">
                  <div v-for="product of vendorProducts(['Ascent'])" :key="product.id" class="checkbox">
                    <label>
                      <input
                        type="checkbox"
                        :value="product"
                        :disabled="requiredProducts.includes(product.id)"
                        v-model="pinnacleProductsSelected"
                      >
                      {{ product.label }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>

          </ValidationObserver>
        </div>

        <!-- Apex Product Selection -->
        <div v-if="currentStep === 'APEXPS'">

          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>APEX Product Selection</h3>
              <h4>Please select the products the dealer will be selling</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="div-group" id="products">
                <div v-for="product of vendorProducts(['Apex'])" :key="product.id" class="checkbox">
                  <label>
                    <input type="checkbox" :value="product" v-model="pinnacleProductsSelected">
                    {{ product.label }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>

        </div>

        <!-- Amplify Product Selection -->
        <div v-if="currentStep === 'AMPPS'">

          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>Ampli-Fi Product Selection</h3>
              <h4>Please select the products the dealer will be selling</h4>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <div class="div-group" id="products">
                <div v-for="product of vendorProducts(['Amplify'])" :key="product.id" class="checkbox">
                  <label>
                    <input type="checkbox" :value="product" v-model="pinnacleProductsSelected">
                    {{ product.label }}
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>

        </div>

        <!-- Step 13 Pinnacle Commision Assignment -->
        <div v-if="currentStep === 'PCM'">
          <ValidationObserver ref="PCM">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Pinnacle Commission Selection</h3>
              </div>
            </div>
            <div class="row div-group">
              <h4>Please select the commission for the products </h4>
              <div class="col-sm-12">
                <div class="table-responsive table-full-width">
                  <el-table class="table-striped" :data="pinnacleProductsSelected">
                    <el-table-column label="Pinnacle Product Name" prop="name"></el-table-column>
                    <el-table-column label="Commission Amount ($)" prop="commission">
                      <template slot-scope="{ row }">
                        <fg-input type="number" placeholder="Commission ($)"
                                  v-model="row.commission"></fg-input>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 14 Hard Packs -->
        <div v-if="currentStep === 'HPK'">
          <ValidationObserver ref="HPK">

            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Hard Pack</h3>
              </div>

            </div>
            <div class="div-group col-sm-12" style="min-width: 1270px;">

              <h4>Would you like a hard pack for the products?</h4>
              <h4>Please Note: Dealer hard packs by Ascent's definition are included in the total
                Dealer Cost and they are remitted to Ascent. They are then paid to the dealer by
                Ascent (Also known as mailbox money).
              </h4>
              <h4>
                A separate signed ACH form and W-9 are required if not being paid to the dealer.
              </h4>

              <div class="row">
                <div class="col-sm-12">
                  <card style="min-width: 1250px">
                    <div slot="header">
                      <h4 class="card-title">Hard Pack Product Selection</h4>
                      <p class="card-category">Please Add Hard Packs if you need them</p>
                      <p class="card-category">
                        <l-button type="primary" round @click="hardPackSelected">Add Hard Pack
                        </l-button>
                      </p>
                    </div>
                  </card>

                  <div v-for="pack in allHardPacks" v-bind:key="pack.id">
                    <card>
                      <div class="table-responsive table-full-width">
                        <h5>Hard Pack {{ pack.id }}</h5>
                        <el-table class="table-striped" :data="pack.packs">
                          <el-table-column label="Name">
                            <template slot-scope="{ row }">
                              <span>
                                {{ row.name }}
                              </span>
                            </template>
                          </el-table-column>

                          <el-table-column label="Commission ($)">
                            <template slot-scope="{ row }">
                              <span>
                                {{ row.commission }}
                                <!--
                                <fg-input
                                  v-model="row.commission"
                                  placeholder="Commission ($)"
                                ></fg-input>
                                -->
                              </span>
                            </template>
                          </el-table-column>
                        </el-table>


                        <h5 style="margin-top:15px;margin-bottom:0;">Payees</h5>
                        <el-table class="table-striped" :data="pack.payees">
                          <el-table-column>
                            <template slot-scope="{ row }">
                              {{ row.userFirst }}

                              <!--
                              <fg-input v-model="row.userFirst" placeholder="First Name"
                                        label="First Name"></fg-input>
                                        -->
                            </template>
                          </el-table-column>
                          <el-table-column>
                            <template slot-scope="{ row }">
                              {{ row.userLast }}
                              <!--
                              <fg-input v-model="row.userLast" placeholder="Last Name"
                                        label="Last Name"></fg-input>
                                        -->
                            </template>
                          </el-table-column>
                          <el-table-column>
                            <template slot-scope="{ row }">
                              {{ row.userEmail }}
                              <!--
                              <fg-input v-model="row.userEmail" placeholder="Email"
                                        label="Email"></fg-input>
                                        -->
                            </template>
                          </el-table-column>
                        </el-table>
                        <l-button type="danger" round @click="deleteHardPack(pack)">Remove Hard Pack
                        </l-button>
                        <br>
                      </div>
                    </card>

                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <!-- <l-button type="primary" @click="setCurrentStep(10)" round>Upload Documents </l-button>-->
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 15 Soft Packs -->
        <div v-if="currentStep === 'SPK'">
          <ValidationObserver ref="SPK">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>Soft Pack</h3>
              </div>
            </div>
            <div class="div-group">

              <h4>Would you like a soft pack for the products?</h4>
              <h4>Please Note: Dealer soft packs by Ascent's definition are included in the total
                Dealer Cost. However, they are NOT remitted to Ascent, as the money stays in the
                dealership.</h4>

              <div class="row">
                <div class="col-sm-12">
                  <card>
                    <div slot="header">
                      <h4 class="card-title">Soft Pack Product Selection</h4>
                      <p class="card-category">Please Add Soft Packs if you need them</p>
                      <p class="card-category">
                        <l-button type="primary" round @click="softPackSelected">Add Soft Pack
                        </l-button>
                      </p>
                    </div>
                    <div class="table-responsive table-full-width">
                      <el-table class="table-striped" :data="allSoftPacks">
                        <el-table-column label="Products">
                          <template slot-scope="{ row }">
                            <div class="row" v-for="item in row" v-bind:key="item.id">
                              <div class="col-sm-12">
                                {{ item.name }}
                                <!-- <fg-input v-model="item.name" placeholder="Name"
                                          label="Name"></fg-input> -->
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Commission">
                          <template slot-scope="{ row }">
                            <div class="row" v-for="item in row" v-bind:key="item.id">
                              <div class="col-sm-12">
                                {{item.commission}}
                                <!-- <fg-input v-model="item.commission" placeholder="Commission ($)"
                                          label="Soft Pack"></fg-input> -->
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Actions">
                          <template slot-scope="{ row }">
                            <l-button class="trash-button" @click="deleteSoftPack(row)"></l-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </card>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Step 15 Dealer Profit Protection -->
        <div v-if="currentStep === 'DPP'">

          <div class="row">
            <div class="col-sm-12">
              <h3>Dealer Profit Protection</h3>
            </div>
          </div>

          <div class="div-group">
            <h4>
              PN VSC and Powertrain Plus Long Term can be set up with Dealer Profit Protection, in addition to Agent Profit Protection.<br/>
              For VSC, we offer $100 to protect $750 or $200 to protect $1500.
            </h4>
            <h4>
              GAP can also be set up with just Agent Profit Protection or Agent and Dealer Profit Protection
            </h4>
            <h4>Would you like to add Dealer Profit Protection?</h4>

            <div class="row">
              <div class="col-sm-12">
                <label>
                  <select class="form-control" v-model="needDealerNCB">
                    <option disabled>Do you need to add Dealer Profit Protection?</option>
                    <option :value="true">Yes</option>
                    <option :value="false">No</option>
                  </select>
                </label>
              </div>
            </div>

            <div class="row" v-if="needDealerNCB">
              <div class="col-sm-12">
                <h4>Select the product type</h4>
                <select class="form-control" name="products"
                        v-model="dealerNCBProductsSelected">
                  <option value="VSC">VSC</option>
                  <option value="GAP">GAP</option>
                </select>
              </div>
            </div>

            <div class="row" v-if="showProfitProtectionVSC">
              <div class="col-sm-12">
                <h4>GAP or VSC?</h4>
                <select class="form-control" v-model="protection_vsc_value">
                </select>
              </div>
            </div>

            <div class="row " v-if="dealerNCBProductsSelected === 'VSC'">
              <div class="col-sm-12">
                <h4>Select the protection pricing</h4>
                <select class="form-control" v-model="protection_vsc_value">
                  <option value="100">100</option>
                  <option value="200">200</option>
                </select>
              </div>
            </div>

            <div class="row" v-if="dealerNCBProductsSelected === 'GAP'">
              <div class="col-sm-12">
                <h4>Select the protection pricing</h4>
                <select class="form-control" v-model="protection_vsc_value">
                  <option value="agent_protection">Agent Profit Protection</option>
                  <option value="agent_and_dealer_protection">Agent and Dealer Profit Protection</option>
                </select>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="nextStep" round>Next</l-button>
            </div>
          </div>
        </div>

        <!-- Step 17 Retail Markups -->
        <div v-if="currentStep === 'FIM'">
          <ValidationObserver ref="FIM">
            <div class="row">
              <div class="col-sm-12 text-center">
                <h3>F&I Markup to Retail</h3>
              </div>
            </div>

            <div class="div-group">
              <div class="row">
                <div class="col-sm-12 text-center">
                  <h4>Would you like to add F&I Markup to Retail?</h4>
                  <div class="row">
                    <div class="col-sm-12">
                      <label>
                        <select class="form-control" v-model="needFIMarkup">
                          <option disabled>Do you need to add F&I markup?</option>
                          <option :value="true">Yes</option>
                          <option :value="false">No</option>
                        </select>
                      </label>
                    </div>
                  </div>
                  <h4>Please Note: If this dealer is located in Florida, an F&I markup is
                    required.</h4>
                </div>
              </div>
              <div class="row" v-if="needFIMarkup">
                <div class="col-sm-12">
                  <card>
                    <div slot="header">
                      <h4 class="card-title">F&I Markup Product Selection</h4>
                      <p class="card-category">Please Add F&I Markup if you need them</p>
                      <p class="card-category">
                        <l-button type="primary" round @click="FIMarkupSelected">Add F&I Markup
                        </l-button>
                      </p>
                    </div>
                    <div class="table-responsive table-full-width">
                      <el-table class="table-striped" :data="allFIMarkup">
                        <el-table-column label="Products">
                          <template slot-scope="{ row }">
                            <div class="row" v-for="item in row" v-bind:key="item.id">
                              <div class="col-sm-12">
                                <fg-input v-model="item.name" placeholder="Name"
                                          label="Name"></fg-input>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="F&I Markup">
                          <template slot-scope="{ row }">
                            <div class="row" v-for="item in row" v-bind:key="item.id">
                              <div class="col-sm-12">
                                <fg-input v-model="item.commission" placeholder="Commission ($)"
                                          label="F&I Markup"></fg-input>
                              </div>
                            </div>
                          </template>
                        </el-table-column>
                        <el-table-column label="Actions">
                          <template slot-scope="{ row }">
                            <l-button class="trash-button" @click="deleteFIMarkup(row)"></l-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </card>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <l-button type="primary" @click="previousStep" round>Previous</l-button>
                <l-button type="primary" @click="nextStep" round>Next</l-button>
              </div>
            </div>
          </ValidationObserver>
        </div>

        <!-- Pinnacle confirmation step -->
        <div v-if="currentStep === 'CNF'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>Confirmation</h3>
              <h4>Confirm details and then click "Submit" to finish processing</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <card>
                <h4 class="card-title">Agent Information</h4>
                <p class="card-category">Please verify the agent information</p>
                <p><label>
                  Agent: {{ agentName }}
                </label></p>
                <p><label>
                  Agency: {{ agency }}
                </label></p>
                <p><label>
                  Address1: {{ agentAddress1 }}
                </label></p>
                <p><label>
                  Address2: {{ agentAddress2 }}
                </label></p>
                <p><label>
                  City: {{ agentCity }}
                </label></p>
                <p><label>
                  State: {{ agentState }}
                </label></p>
                <p><label>
                  Zip: {{ agentZip }}
                </label></p>
                <p><label>
                  Agent Email: {{ agentEmail }}
                </label></p>
              </card>
              <card>
                <h4 class="card-title">Dealer Information</h4>
                <p class="card-category">Please verify the dealer information</p>
                <p><label>
                  Dealer Name: {{ dealerName }}
                </label></p>
                <p><label>
                  Address1: {{ dealerAddress1 }}
                </label></p>
                <p><label>
                  Address2: {{ dealerAddress2 }}
                </label></p>
                <p><label>
                  Address2: {{ dealerAddress2 }}
                </label></p>
                <p><label>
                  City: {{ dealerCity }}
                </label></p>
                <p><label>
                  State: {{ dealerState }}
                </label></p>
                <p><label>
                  Zip: {{ dealerZip }}
                </label></p>
                <p><label>
                  Dealer Contact First: {{ dealerFirstName }}
                </label></p>
                <p><label>
                  Dealer Contact Last: {{ dealerLastName }}
                </label></p>
                <p><label>
                  Dealer Contact Phone: {{ dealerPhone }}
                </label></p>
                <p><label>
                  Dealer Contact Email: {{ dealerEmail }}
                </label></p>
                <p v-if="haveOnSiteRepairCenter"><label>
                  Has Onsite Repair Center: Yes
                </label></p>
                <p v-else><label>
                  Has Onsite Repair Center: No
                </label></p>
                <p v-if="havePreferredRepair"><label>
                  Preferred Repair Center: {{ preferredRepairCenter }}
                </label></p>
                <p v-if="havePreferredRepair === false && havePreferredRepair === false"><label>
                  No Preferred Repair Center
                </label></p>
              </card>
              <card>
                <h4 class="card-title">Additional Information</h4>
                <p class="card-category">Please verify the additional information</p>
                <p v-if="needSPP"><label>
                  SPP Needed: Yes
                </label></p>
                <p v-else><label>
                  SPP Needed: No
                </label></p>
              </card>
              <card>
                <h4 class="card-title">PCRS Users</h4>
                <p class="card-category">Please verify the PCRS users</p>
                <div v-for="user of users" v-bind:key="user.name">
                  <card>
                    <p><label>Email: {{ user.email }}</label></p>
                    <p><label>First: {{ user.first_name }}</label></p>
                    <p><label>Last: {{ user.last_name }}</label></p>
                    <p><label>User Role: {{ user.user_role }}</label></p>
                  </card>
                </div>
              </card>
              <card>
                <h4 class="card-title">Lenders</h4>
                <p class="card-category">Please verify the Lenders</p>
                <div v-for=" (lender, index) of lenders" v-bind:key="index">
                  <card>
                    <p><label>Lender: {{ lender }}</label></p>
                  </card>
                </div>
              </card>
              <card>
                <h4 class="card-title">Selected Menu Options</h4>
                <p class="card-category">Please verify the menu options</p>
                <label v-for="menu of selectedOptions" v-bind:key="menu.id">
                  Menu Option: {{ menu.label }}
                </label>
              </card>
              <card>
                <h4 class="card-title">Pinnacle Product Information</h4>
                <p class="card-category">Please verify the pinnacle product information</p>
                <label v-for="product of pinnacleProductsSelected" v-bind:key="product.id">
                  Product Name: {{ product.name }} - Commission: ${{ product.commission }}
                </label>
              </card>
              <card>
                <h4 class="card-title">Hard Pack Information</h4>
                <p class="card-category">Please verify the hard pack product information</p>
                <div class="col-sm-12"
                     v-for="product of allHardPacks" :key="product.id">
                  <card>
                    <div class="col-sm-12">
                      <h5>Hard Pack {{ product.id }}</h5>
                      Products:
                      <div
                        v-for="item in product.packs"
                        :key="item.id"
                      >
                        <div class="col-sm-12">
                          <p><label>
                            Name: {{ item.name }}<br>Commission: ${{ item.commission }}
                          </label></p>
                        </div>
                      </div>
                      Payees:
                      <div
                        v-for="item in product.payees"
                        :key="item.id"
                      >
                        <div class="col-sm-12">
                          <p><label>
                            {{ item.userFirst }} {{ item.userLast }} @ {{ item.userEmail }}
                          </label></p>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </card>
              <card>
                <h4 class="card-title">Soft Pack Information</h4>
                <p class="card-category">Please verify the soft pack product information</p>
                <div class="col-sm-12"
                     v-for="product of allSoftPacks" :key="product.id">
                  <card>
                    <div class="col-sm-12">
                      <h5>Soft Pack {{ product[0].packID }}</h5>
                      Products:
                      <div
                        class="row"
                        v-for="item in product"
                        :key="item.id"
                      >
                        <div class="col-sm-12">
                          <p><label>
                            Name: {{ item.name }}<br>Commission: ${{ item.commission }}
                          </label></p>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </card>
              <card>
                <h4 class="card-title">NCB</h4>
                <p class="card-category">Please verify the NCB information</p>
                <div v-for="product of dealerNCBProductsSelected" v-bind:key="product.id">
                  <p><br><label>
                    Name: {{ product.name }}
                  </label></p>
                </div>
              </card>
              <card>
                <h4 class="card-title">F&I Markup Information</h4>
                <p class="card-category">Please verify the F&I Markup product information</p>
                <div class="col-sm-12"
                     v-for="product of allFIMarkup" :key="product.id">
                  <card>
                    <div class="col-sm-12">
                      <h5>F&I Markup {{ product[0].packID }}</h5>
                      Products:
                      <div
                        class="row"
                        v-for="item in product"
                        :key="item.id"
                      >
                        <div class="col-sm-12">
                          <p><label>
                            Name: {{ item.name }}<br>Commission: ${{ item.commission }}
                          </label></p><br>
                        </div>
                      </div>
                    </div>
                  </card>
                </div>
              </card>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <l-button type="primary" @click="previousStep" round>Previous</l-button>
              <l-button type="primary" @click="finishPinnacleStep" round>Submit</l-button>
            </div>
          </div>
        </div>

        <!--Elevate finish-->
        <div v-if="currentStep === 'ELF'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>Elevate</h3>
              <h4>Thank you for submitting your New Dealer paperwork!<br>We will begin setting them
                up shortly. If you have any questions, or need to change anything, please don't
                hesitate to contact our Onboarding team at <a
                  href="mailto:onboarding@ascentadmin.com" target="_newtab">onboarding@ascentadmin.com</a>.<br>Thank
                you for your partnership!</h4>
              <a href="https://ascentadmin.com">Visit our website</a>
            </div>
          </div>
        </div>

        <!--Pinnacle finish-->
        <div v-if="currentStep === 'PNF'">
          <div class="row">
            <div class="col-sm-12 text-center">
              <h3>Pinnacle</h3>
              <h4>Thank you for submitting your New Dealer paperwork!<br>We will begin setting them
                up shortly. If you have any questions, or need to change anything, please don't
                hesitate to contact our Onboarding team at <a
                  href="mailto:onboarding@ascentadmin.com" target="_newtab">onboarding@ascentadmin.com</a>.<br>Thank
                you for your partnership!</h4>
              <a href="https://ascentadmin.com">Visit our website</a>
            </div>
          </div>
        </div>

        <div v-if="currentStep === 'MOR'">
          <div class="row">
            <div class="col-sm-12 text-center"><br><br>
              <h5>Click on the buttons below to download our user guides:</h5>
              <l-button @click="warrantyClaimGuide()">Starting an Online Claim</l-button>
              <br>
              <l-button @click="contractProcessingGuide()">How to Remit and Pay for Contracts
              </l-button>
              <br>
              <l-button @click="contractCancelingGuide()">How to Cancel a Contract</l-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      center
      title="Add Soft Pack"
      :visible.sync="modals.addSoftPack">
      <card v-if="!showSoftPackCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the products you want in the soft pack</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-checkbox-group v-model="softPackProductsSelected">
              <el-checkbox v-for="product in softPackProducts" :label="product" :key="product.id">
                {{ product.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <l-button type="primary" @click="selectSoftPackProducts"
                        round>Select Products</l-button>&nbsp;
              <l-button type="primary" @click="closeModal('addSoftPack')" round>Cancel</l-button>&nbsp;
            </span>
      </card>
      <card v-if="showSoftPackCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the commission amounts you want in the soft pack</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive table-full-width">
              <el-table class="table-striped" :data="softPackProductsSelected">
                <el-table-column label="Selected Products" prop="name"></el-table-column>
                <el-table-column label="Commission Amount ($)" prop="commission">
                  <template slot-scope="{ row }">
                    <fg-input type="number" placeholder="Commission ($)"
                              v-model="row.commission"></fg-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <l-button type="primary" @click="saveSoftPack('addSoftPack')" round>Save</l-button>&nbsp;
              <l-button type="primary" @click="closeModal('addSoftPack')" round>Cancel</l-button>&nbsp;
            </span>
      </card>
    </el-dialog>
    <el-dialog
      center
      title="Add F&I Markup"
      :visible.sync="modals.addFIMarkup">
      <card v-if="!showFIMarkupCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the products you want in the F&I Markup</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <select class="form-control" id="products" name="products"
                    v-model="FIMarkupProductsSelected" multiple size="18">
              <option v-for="product of FIMarkupProducts" :value="product"
                      :key="product.id">
                {{
                  product.label
                }}
              </option>
            </select>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <l-button type="primary" @click="selectFIMarkupProducts"
                        round>Select Products</l-button>&nbsp;
              <l-button type="primary" @click="closeModal('addFIMarkup')" round>Cancel</l-button>&nbsp;
            </span>
      </card>
      <card v-if="showFIMarkupCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the commission amounts you want in the F&I Markup</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive table-full-width">
              <el-table class="table-striped" :data="FIMarkupProductsSelected">
                <el-table-column label="Selected Products" prop="name"></el-table-column>
                <el-table-column label="Commission Amount ($)" prop="commission">
                  <template slot-scope="{ row }">
                    <fg-input type="number" placeholder="Commission ($)"
                              v-model="row.commission"></fg-input>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <l-button type="primary" @click="saveFIMarkup('addFIMarkup')" round>Save</l-button>&nbsp;
              <l-button type="primary" @click="closeModal('addFIMarkup')" round>Cancel</l-button>&nbsp;
            </span>
      </card>
    </el-dialog>
    <el-dialog
      center
      title="Add Hard Pack"
      :visible.sync="modals.addHardPack">
      <card v-if="!showHardPackCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the products you want in the hard pack</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-checkbox-group v-model="hardPackProductsSelected">
              <el-checkbox v-for="product in hardPackProducts" :label="product" :key="product.id">
                {{ product.label }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
              <l-button type="primary" @click="selectHardPackProducts"
                        round>Select Products</l-button>&nbsp;
              <l-button type="primary" @click="cancelHardPack('addHardPack')" round>Cancel</l-button>&nbsp;
            </span>
      </card>
      <card v-if="showHardPackCommissions">
        <div class="row">
          <div class="col-sm-12">
            <h4>Select the commission amounts you want and enter dealer details:</h4>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive table-full-width">
              <ValidationObserver ref="steph">
                <div class="row">
                  <div class="col">
                    <el-checkbox label="User Dealer as Payee"
                                 @change="addHardPackCommissionedUser(true)"></el-checkbox>
                  </div>
                </div>
                <el-table class="table-striped" :data="hardPackProductsSelected">
                  <el-table-column label="Selected Products" prop="name"></el-table-column>
                  <el-table-column label="Commission Amount ($)" prop="commission">
                    <template slot-scope="{ row }">
                      <fg-input type="number" placeholder="Commission ($)"
                                v-model="row.commission"></fg-input>
                    </template>
                  </el-table-column>
                </el-table>
                <br>
                <div class="row">
                  <div class="col-sm-12">
                    <h4>Payees:</h4>
                  </div>
                </div>
                <el-table class="table-striped" :data="hardPackCommissions">
                  <el-table-column label="Dealer Email" prop="dealerEmail">
                    <template slot-scope="{ row }">
                      <ValidationProvider
                        rules="email"
                        v-slot="{ passed, failed }">
                        <fg-input
                          :error="failed ? 'Please enter a valid email': null"
                          :hasSuccess="passed"
                          placeholder="Dealer Email"
                          v-model="row.userEmail"/>
                      </ValidationProvider>
                    </template>
                  </el-table-column>
                  <el-table-column label="First Name" prop="dealerFirst">
                    <template slot-scope="{ row }">
                      <fg-input
                        placeholder="First Name"
                        v-model="row.userFirst"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="Last Name" prop="dealerLast">
                    <template slot-scope="{ row }">
                      <fg-input
                        placeholder="Last Name"
                        v-model="row.userLast"/>
                    </template>
                  </el-table-column>
                  <el-table-column label="Payment Method" prop="paymentMethod">
                    <template slot-scope="{ row }">
                      <select name="paymentHardPackMethod"
                              class="form-control"
                              v-model="row.paymentMethod">
                        <option disabled>Please Select a payment option...</option>
                        <option value="check">Check</option>
                        <option value="ach">ACH</option>
                      </select>
                    </template>
                  </el-table-column>
                  <el-table-column label="Remove" v-if="moreThenOnePayee">
                    <template slot-scope="{ row }">
                      <l-button type="primary" @click="removeHardPackCommissionedUser(row)"
                                round>Remove<br>Payee
                      </l-button>&nbsp;
                    </template>
                  </el-table-column>
                </el-table>
                <div class="row">
                  <div class="col">
                    <!--
                    <l-button type="primary" @click="addHardPackCommissionedUser"
                              round>Add New Payee
                    </l-button>&nbsp;-->
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <l-button type="primary" @click="checkHardPackValid('addHardPack')" round>Save</l-button>&nbsp;
          <l-button type="primary" @click="cancelHardPack('addHardPack')" round>Cancel</l-button>&nbsp;
        </span>
      </card>
    </el-dialog>
    <el-dialog
      center
      title="Success"
      :visible.sync="modals.success">
      <span>{{ success_txt }}</span>
      <span slot="footer" class="dialog-footer">
              <button class="btn btn-success" @click="closeModal('success')">OK</button>&nbsp;
            </span>
    </el-dialog>
    <el-dialog
      center
      title="Error"
      :visible.sync="modals.error">
      <div class="text-center">
        <span>Error: {{ error_txt }}</span>
        <br>
        <span slot="footer" class="dialog-footer">
<button class="btn btn-danger" @click="closeModal('error')">OK</button>&nbsp;
</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Message, Dialog, Table, TableColumn, Checkbox, CheckboxGroup } from 'element-ui';
import FilesAPIHandler from "src/servicehandlers/FilesAPIHandler";
import SessionAPIService from "src/servicehandlers/SessionAPIService";
import DealerAPIHandler from "src/servicehandlers/DealerAPIService";
import AgentAPIService from "src/servicehandlers/AgentAPIService";
import ProductsAPIService from "src/servicehandlers/ProductsAPIService";
import MenuOptionsAPIService from "src/servicehandlers/MenuOptionsAPIService";
import { email, min, numeric, required } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

const filesAPIHandler = new FilesAPIHandler();
const sessionAPIService = new SessionAPIService();
const dealerAPIHandler = new DealerAPIHandler();
const agentAPIHandler = new AgentAPIService();
const productsAPIService = new ProductsAPIService();

const menuOptionsAPIService = new MenuOptionsAPIService();

extend("email", email);
extend("required", required);
extend("zip", numeric);
extend("min", min);
extend('phone', {
  validate: value => {
    const pattern = /^(?:\+?1[-.●]?)?\(?([2-9][0-8][0-9])\)?[-.●]?([2-9][0-9]{2})[-.●]?([0-9]{4})$/;
    return pattern.test(value);
  },
  message: 'The {_field_} field must be a valid phone number'
});

Array.prototype.sortProducts = function(){
  const vendorOrder = ["Pinnacle", "Ascent", "Apex", "Amplify"];

  return this
    //.slice() // Clone array to avoid in-place sorting
    .sort((a, b) => {
      // Sort by vendor order first
      const vendorComparison = vendorOrder.indexOf(a['vendor']) - vendorOrder.indexOf(b['vendor']);

      // If vendors are the same, sort by name within that vendor group
      if (vendorComparison === 0) {
        return a['name'].localeCompare(b['name']);
      }

      return vendorComparison;
    });
};

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Message.name]: Message,
  },
  name: "Wizard",
  data() {
    return {
      success_txt: null,
      requiredProducts: [],
      error_txt: null,
      spinning: false,
      modals: {
        error: false,
        success: false,
        addHardPack: false,
        addSoftPack: false,
        addFIMarkup: false,
      },
      steps: [
        {message: "Agent Information", sid: "AIN"},
        {message: "Dealership Information", sid: "DIN"},
        {message: "Preferred Repair Center", sid: "PRC"},
        {message: "Authorized Signer Information", sid: "ASI"},
        {message: "Remitter Information", sid: "RIN"},
        {message: "Menu Integration", sid: "MIN"},
        {message: "SPP", sid: "SPP"},
        {message: "Lenders", sid: "LEN"},
        {message: "PCRS User Creation", sid: "PUC"},
        {message: "Zoho Sign", sid: "ZOS"},
        {message: "Reinsured or Walk Away", sid: "EWA"},
        {message: "Pinnacle Product Selection", sid: "PPS"},
        {message: "APEX Product Selection", sid: "APEXPS"},
        {message: "Ampli-Fi Product Selection", sid: "AMPPS"},
        {message: "Pinnacle Commission ", sid: "PCM"},
        {message: "Hard Packs", sid: "HPK"},
        {message: "Soft Packs", sid: "SPK"},
        {message: "Dealer Profit Protection", sid: "DPP"},
        {message: "F&I Markup to Retail", sid: "FIM"},
        {message: "Confirmation", sid: "CNF"},
        {message: "Elevate Finish", sid: "ELF"},
        {message: "Pinnacle Finish", sid: "PNF"},
      ],
      lenders: ['', '', ''],
      showProfitProtectionVSC: false,
      showProfitProtectionGAP: false,
      softPackProductsSelected: [],
      FIMarkupProductsSelected: [],
      dealerNCBProductsSelected: null,
      needDealerNCB: null,
      needHardPack: null,
      needSoftPack: null,
      needFIMarkup: null,
      needCarCare: false,
      needSPP: false,
      needLine5: false,
      haveOnSiteRepairCenter: null,
      havePreferredRepair: null,
      preferredRepairCenter: "",
      showHardPackCommissions: false,
      showSoftPackCommissions: false,
      showFIMarkupCommissions: false,
      hardPackProductsSelected: [],
      hardPackCommissions: [],
      moreThenOnePayee: false,
      protection_vsc_value: 'VSC',
      pinnacleProductsSelected: [],
      dealerNCBProducts: [],
      pinnacleVSCProduct: [],
      hardPackProducts: [],
      softPackProducts: [],
      FIMarkupProducts: [],
      pinnacleProducts: [],
      currentStep: 'AIN',
      copyAgentEmail: false,
      allHardPacks: [],
      hardPacksRequiredError: false,
      softPacksRequiredError: false,
      allSoftPacks: [],
      allFIMarkup: [],
      docusignUsers: [],
      viewUsers: [],
      files: [],
      isActive: false,
      pinnacleOrElevate: null,
      selectAll: null,
      agency: null,
      paymentRemitMethodError: false,
      menuIntegrationError: false,
      newUserRoleError: false,
      agentName: null,
      agentAddress1: null,
      agentAddress2: null,
      agentCity: null,
      agentState: null,
      agentZip: null,
      agentEmail: null,
      agentStateError: false,
      dealerName: null,
      dealerAddress1: null,
      dealerAddress2: null,
      dealerCity: null,
      dealerState: null,
      dealerZip: null,
      dealerFirstName: null,
      dealerStateError: false,
      dealerLastName: null,
      dealerPhone: null,
      dealerEmail: null,
      docFirstName: null,
      docLastName: null,
      docEmail: null,
      activeStep: null,
      monthlyRemitFirst: '',
      monthlyRemitLast: '',
      monthlyRemitWebsite: '',
      paymentRemitFirst: '',
      paymentRemitLast: '',
      paymentRemitEmail: '',
      paymentRemitMethod: null,
      newUser: {
        password: null,
        email: null,
        firstName: null,
        lastName: null,
        user_role: null,
      },
      showModal: false,
      users: [],
      refresh_token: 0,
      needMenuIntegration: null,
      options: [],
      selectedOptions: [],
      session_id: null,
      states: [
        { name: 'Alabama', abbreviation: 'AL' },
        { name: 'Alaska', abbreviation: 'AK' },
        { name: 'Arizona', abbreviation: 'AZ' },
        { name: 'Arkansas', abbreviation: 'AR' },
        { name: 'California', abbreviation: 'CA' },
        { name: 'Colorado', abbreviation: 'CO' },
        { name: 'Connecticut', abbreviation: 'CT' },
        { name: 'Delaware', abbreviation: 'DE' },
        { name: 'Florida', abbreviation: 'FL' },
        { name: 'Georgia', abbreviation: 'GA' },
        { name: 'Hawaii', abbreviation: 'HI' },
        { name: 'Idaho', abbreviation: 'ID' },
        { name: 'Illinois', abbreviation: 'IL' },
        { name: 'Indiana', abbreviation: 'IN' },
        { name: 'Iowa', abbreviation: 'IA' },
        { name: 'Kansas', abbreviation: 'KS' },
        { name: 'Kentucky', abbreviation: 'KY' },
        { name: 'Louisiana', abbreviation: 'LA' },
        { name: 'Maine', abbreviation: 'ME' },
        { name: 'Maryland', abbreviation: 'MD' },
        { name: 'Massachusetts', abbreviation: 'MA' },
        { name: 'Michigan', abbreviation: 'MI' },
        { name: 'Minnesota', abbreviation: 'MN' },
        { name: 'Mississippi', abbreviation: 'MS' },
        { name: 'Missouri', abbreviation: 'MO' },
        { name: 'Montana', abbreviation: 'MT' },
        { name: 'Nebraska', abbreviation: 'NE' },
        { name: 'Nevada', abbreviation: 'NV' },
        { name: 'New Hampshire', abbreviation: 'NH' },
        { name: 'New Jersey', abbreviation: 'NJ' },
        { name: 'New Mexico', abbreviation: 'NM' },
        { name: 'New York', abbreviation: 'NY' },
        { name: 'North Carolina', abbreviation: 'NC' },
        { name: 'North Dakota', abbreviation: 'ND' },
        { name: 'Ohio', abbreviation: 'OH' },
        { name: 'Oklahoma', abbreviation: 'OK' },
        { name: 'Oregon', abbreviation: 'OR' },
        { name: 'Pennsylvania', abbreviation: 'PA' },
        { name: 'Rhode Island', abbreviation: 'RI' },
        { name: 'South Carolina', abbreviation: 'SC' },
        { name: 'South Dakota', abbreviation: 'SD' },
        { name: 'Tennessee', abbreviation: 'TN' },
        { name: 'Texas', abbreviation: 'TX' },
        { name: 'Utah', abbreviation: 'UT' },
        { name: 'Vermont', abbreviation: 'VT' },
        { name: 'Virginia', abbreviation: 'VA' },
        { name: 'Washington', abbreviation: 'WA' },
        { name: 'West Virginia', abbreviation: 'WV' },
        { name: 'Wisconsin', abbreviation: 'WI' },
        { name: 'Wyoming', abbreviation: 'WY' }
      ],
    };
  },
  watch: {
    pinnacleProductsSelected: {
      handler(newValue, oldValue) {
        this.requiredProducts = [];
        this.addRequiredProducts(newValue);
      },
      deep: true
    }
  },
  mounted() {
    this.createSessionID();
    this.getPinnacleProducts();
    this.getMenuOptions();
    window.onbeforeunload = function (event) {
      event.preventDefault();
    }
  },
  computed: {
 },
  methods: {
    vendorProducts(vendorNames) {
      return this.pinnacleProducts
        .filter((product) => vendorNames.includes(product['vendor']))
        .sort((a, b) => a['vendor'].localeCompare(b['vendor']));
    },
    findStepIndex(sidInput) {
      return this.steps.findIndex(step => step.sid === sidInput);
    },
    dealerOnBoardCheck(row) {
      if (row[0]) {
        row = row[0];
      }

      let f, l, e, a1, a2, c, s, z;

      if (this.currentStep === 'ZOS') {
        f = row.first;
        l = row.last;
        e = row.email;
        a1 = this.dealerAddress1;
        a2 = this.dealerAddress2;
        c = this.dealerCity;
        s = this.dealerState;
        z = this.dealerZip;
      } else if (!row.userFirst || !row.userLast || !row.userEmail) {
        const self = this;
        this.error_txt = 'Please enter a first name, last name, and email';
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false;
        }, 3000);
        return;
      } else {
        f = row.userFirst;
        l = row.userLast;
        e = row.userEmail;
      }

      /*
       <el-table class="table-striped" :data="viewUsers">
       <el-table-column label="First Name" prop="first"></el-table-column>
       <el-table-column label="Last Name" prop="last"></el-table-column>
       <el-table-column label="Email" prop="email"></el-table-column>
       <el-table-column label="Action">
       <template slot-scope="{ row }">
       <l-button type="primary" @click="dealerOnBoardACH(dealerOnBoardCheck(row))"
       v-if="paymentRemitMethod === 'ach'">Create<br>ACH<br>Docusign
       </l-button>
       <l-button type="primary" @click="dealerOnBoardW9(dealerOnBoardCheck(row))">
       Create<br>W-9<br>Docusign<br>Document
       </l-button>
       <l-button type="primary" @click="dealerOnBoardDoc(dealerOnBoardCheck(row))">
       Create<br>Dealer<br>Onboard Document
       </l-button>
       </template>
       </el-table-column>
       </el-table>
       */

      return {
        first: f,
        last: l,
        email: e,
        address1: a1,
        address2: a2,
        city: c,
        state: s,
        zip: z
      };
    },
    async dealerOnBoardDoc(info) {
      if (info !== undefined) {
        let response = null;
        try {
          //response = await productsAPIService.docusignOnboard(info, this.$router);
          info.templatename = 'onboard';
          response = await productsAPIService.zohoDocument(info, this.$router);
          this.docusignFeedback(response);
        } catch (e) {
          this.docusignFeedback(e);
        }
      }
    },
    async dealerOnBoardACH(info) {
      if (info !== undefined) {
        let response = null;
        try {
          //response = await productsAPIService.docusignACH(info, this.$router);
          info.templatename = 'ach';
          response = await productsAPIService.zohoDocument(info, this.$router);
          this.docusignFeedback(response);
        } catch (e) {
          this.docusignFeedback(e);
        }
      }
    },
    async dealerOnBoardW9(info) {
      if (info !== undefined) {
        let response = null;
        try {
          //response = await productsAPIService.docusignW9(info, this.$router);
          info.templatename = 'w9';
          response = await productsAPIService.zohoDocument(info, this.$router);
          this.docusignFeedback(response);
        } catch (e) {
          this.docusignFeedback(e);
        }
      }
    },
    docusignFeedback(res) {
      if (res === "Sent!") {
        Message.success('Zoho Sign email sent!  Check your email to fill out the form.')
      } else {
        Message.error('ZOho Sign send failed!.')
      }
    },
    async getMenuOptions() {
      try {
        this.options = await menuOptionsAPIService.get(this.$router);
      } catch (e) {
      }
    },
    async getPinnacleProducts() {
      let pinnacle_products = null;
      try {

        pinnacle_products = await productsAPIService.get(this.$router);

        pinnacle_products.sortProducts();

        for (const product of pinnacle_products) {
          const copiedObject = JSON.parse(JSON.stringify(product));
          copiedObject.commission = 0;
          copiedObject.desired = 0;
          copiedObject.packID = 0;
          copiedObject.agent_ncb = 0;
          copiedObject.dealer_ncb = 0;
          copiedObject.userFirst = "";
          copiedObject.userLast = "";
          copiedObject.userEmail = "";
          this.pinnacleProducts.push(copiedObject);
        }
        for (const product of pinnacle_products) {
          const copiedObject = JSON.parse(JSON.stringify(product));
          copiedObject.commission = 0;
          copiedObject.desired = 0;
          copiedObject.packID = 0;
          copiedObject.agent_ncb = 0;
          copiedObject.dealer_ncb = 0;
          copiedObject.userFirst = "";
          copiedObject.userLast = "";
          copiedObject.userEmail = "";
          this.hardPackProducts.push(copiedObject);
        }
        for (const product of pinnacle_products) {
          const copiedObject = JSON.parse(JSON.stringify(product));
          copiedObject.commission = 0;
          copiedObject.desired = 0;
          copiedObject.packID = 0;
          copiedObject.agent_ncb = 0;
          copiedObject.dealer_ncb = 0;
          copiedObject.userFirst = "";
          copiedObject.userLast = "";
          copiedObject.userEmail = "";
          this.softPackProducts.push(copiedObject);
        }
        for (const product of pinnacle_products) {
          const copiedObject = JSON.parse(JSON.stringify(product));
          copiedObject.commission = 0;
          copiedObject.desired = 0;
          copiedObject.packID = 0;
          copiedObject.agent_ncb = 0;
          copiedObject.dealer_ncb = 0;
          copiedObject.userFirst = "";
          copiedObject.userLast = "";
          copiedObject.userEmail = "";
          this.dealerNCBProducts.push(copiedObject);
        }
        for (const product of pinnacle_products) {
          if (product.label === "Pinnacle VSC") {
            const copiedObject = JSON.parse(JSON.stringify(product));
            copiedObject.commission = 0;
            copiedObject.desired = 0;
            copiedObject.packID = 0;
            copiedObject.agent_ncb = 0;
            copiedObject.dealer_ncb = 0;
            copiedObject.userFirst = "";
            copiedObject.userLast = "";
            copiedObject.userEmail = "";
            this.pinnacleVSCProduct.push(copiedObject);
          }
        }
        for (const product of pinnacle_products) {
          const copiedObject = JSON.parse(JSON.stringify(product));
          copiedObject.commission = 0;
          copiedObject.desired = 0;
          copiedObject.packID = 0;
          copiedObject.agent_ncb = 0;
          copiedObject.dealer_ncb = 0;
          copiedObject.userFirst = "";
          copiedObject.userLast = "";
          copiedObject.userEmail = "";
          this.FIMarkupProducts.push(copiedObject);
        }


      } catch (e) {
        //console.log('Failed to get products: ', e);
      }
    },
    async createSessionID() {
      let session_id = null;
      try {
        const parms = {
          session_id: Math.random().toString(36).substring(2, 15)
        };
        session_id = await sessionAPIService.create(parms, this.$router);
        this.session_id = session_id.id;
        //console.log('Session ID: ', this.session_id);
      } catch (e) {
        //console.log('Failed to create session: ', e);
      }
    },
    async editHardPack() {
      this.docusignUsers = [];
      for (let item of this.allHardPacks) {
        for (let arr of item) {
          if (!arr.userFirst || !arr.userLast || !arr.userEmail) {
            continue;
          }
          let user = {
            first: arr.userFirst,
            last: arr.userLast,
            email: arr.userEmail,
          }
          this.docusignUsers.push(user);
        }
      }
    },
    deleteHardPack(row) {
      this.moreThenOnePayee = false;
      for (const hardpack of row.payees) {
        let first = hardpack.userFirst;
        let last = hardpack.userLast;
        let email = hardpack.userEmail;
        let user = { first, last, email };
        this.removeDocusignUser(user);
      }
      let index = this.allHardPacks.findIndex(x => x === row)
      this.allHardPacks.splice(index, 1);
      for (let hardpack of this.allHardPacks) {
        hardpack.id = this.allHardPacks.findIndex(x => x === hardpack) + 1
      }
    },
    deleteSoftPack(row) {
      let index = this.allSoftPacks.findIndex(x => x === row)
      this.allSoftPacks.splice(index, 1);
      for (let softpack of this.allSoftPacks) {
        for (let product of softpack) {
          product.packID = this.allSoftPacks.findIndex(x => x === softpack) + 1
        }
      }
    },
    addRequiredProducts(selectedProducts) {
      // Iterate over a copy of pinnacleProductsSelected to avoid modifying it while iterating
      const selectedProductsCopy = [...selectedProducts];


      selectedProductsCopy.forEach((selectedProduct) => {
        // Check if the selected product has a `required_product_id` property
        if (selectedProduct.required_product_id) {
          // Find the required product in the `pinnacleProducts` array
          const requiredProduct = this.pinnacleProducts.find( ( product ) => product.id === selectedProduct.required_product_id);

          if (requiredProduct) {
            // Check if the required product is already in the selected products array
            const alreadySelected = this.pinnacleProductsSelected.some(product => product.id === requiredProduct.id);

            const alreadyRequired = this.requiredProducts.includes( requiredProduct.id );

            if (!alreadySelected) {
              this.pinnacleProductsSelected.push({ ...requiredProduct });
            }

            if (!alreadyRequired) {
              this.requiredProducts.push( requiredProduct.id );
            }

          }
        }
      });

      this.refresh_token = this.refresh_token + 1;
    },
    deleteFIMarkup(row) {
      let index = this.allFIMarkup.findIndex(x => x === row)
      this.allFIMarkup.splice(index, 1);
      for (let fimarkup of this.allFIMarkup) {
        for (let product of fimarkup) {
          product.packID = this.allFIMarkup.findIndex(x => x === fimarkup) + 1
        }
      }
    },
    softPackSelected() {
      this.needSoftPack = true;
      if (this.needSoftPack) {
        this.showSoftPackCommissions = false;
        this.openModal('addSoftPack');
      }
    },
    FIMarkupSelected() {
      this.needFIMarkup = true;
      if (this.needFIMarkup) {
        this.showFIMarkupCommissions = false;
        this.openModal('addFIMarkup');
      }
    },
    hardPackSelected() {
      this.needHardPack = true;
      if (this.needHardPack) {
        this.showHardPackCommissions = false;
        this.openModal('addHardPack');
      }
    },
    addHardPackCommissionedUser(dealer = false) {
      if (dealer) {
        let userFirst = this.dealerFirstName;
        let userLast = this.dealerLastName;
        let userEmail = this.dealerEmail;
        let paymentMethod = this.paymentRemitMethod;
        let user = { userFirst, userLast, userEmail, paymentMethod }
        this.hardPackCommissions.push(user);
        if (this.hardPackCommissions.length > 1) {
          this.moreThenOnePayee = true
        }
      } else {
        let userFirst = ''
        let userLast = ''
        let userEmail = ''
        let paymentMethod = ''
        let user = { userFirst, userLast, userEmail, paymentMethod }
        this.hardPackCommissions.push(user)
        if (this.hardPackCommissions.length > 1) {
          this.moreThenOnePayee = true
        }
      }
    },
    removeHardPackCommissionedUser(row) {
      let index = this.hardPackCommissions.findIndex(x => x === row)
      this.hardPackCommissions.splice(index, 1)
      if (this.hardPackCommissions.length <= 1) {
        this.moreThenOnePayee = false
      }
    },
    selectHardPackProducts() {
      this.showHardPackCommissions = true;
      this.addHardPackCommissionedUser()
    },
    cancelHardPack(name) {
      this.moreThenOnePayee = false;
      this.hardPackCommissions = [];
      this.closeModal(name);
    },
    selectSoftPackProducts() {
      this.showSoftPackCommissions = true;
    },
    selectFIMarkupProducts() {
      this.showFIMarkupCommissions = true;
    },
    onDrop(e) {
      this.isActive = false;
      const files = e.dataTransfer.files;
      this.uploadFiles(files);
    },
    async checkHardPackValid(name) {
      let isValid = false;
      isValid = await this.$refs.steph.validate();
      if (!isValid) {
        return;
      }
      this.saveHardPack(name);
    },
    async saveHardPack(name) {
      this.closeModal(name);
      const copiedObjects = {
        'payees': [],
        'packs': [],
        'id': 0,
      };

      for (const user of this.hardPackCommissions) {
        if (user) {
          this.saveDocusign(user);

          if (this.paymentRemitMethod === 'ach') {
            //await this.dealerOnBoardACH(user);
          }
          //await this.dealerOnBoardW9(user);
        }
      }

      for (const hardpack of this.hardPackProductsSelected) {
        hardpack.packID = this.allHardPacks.length + 1
        const copiedObject = JSON.parse(JSON.stringify(hardpack));
        copiedObjects['packs'].push(copiedObject);
        hardpack.commission = 0;
        hardpack.desired = 0;
        hardpack.packID = 0;
        hardpack.agent_ncb = 0;
        hardpack.dealer_ncb = 0;
        hardpack.paymentMethod = '';
      }

      copiedObjects['payees'] = this.hardPackCommissions;
      copiedObjects['id'] = copiedObjects['packs'][0]['packID']
      this.allHardPacks.push(copiedObjects);
      this.hardPackProductsSelected = [];
      this.hardPackCommissions = [];
    },
    saveSoftPack(name) {
      this.closeModal(name);
      const copiedObjects = [];
      for (const softpack of this.softPackProductsSelected) {
        softpack.packID = this.allSoftPacks.length + 1
        const copiedObject = JSON.parse(JSON.stringify(softpack));
        copiedObjects.push(copiedObject);
        softpack.commission = 0;
        softpack.agent_ncb = 0;
        softpack.dealer_ncb = 0;
        softpack.packID = 0;
      }
      this.allSoftPacks.push(copiedObjects);
      this.softPackProductsSelected = [];
    },
    saveFIMarkup(name) {
      this.closeModal(name);
      const copiedObjects = [];
      for (const fimarkup of this.FIMarkupProductsSelected) {
        fimarkup.packID = this.allFIMarkup.length + 1
        const copiedObject = JSON.parse(JSON.stringify(fimarkup));
        copiedObjects.push(copiedObject);
        fimarkup.commission = 0;
        fimarkup.agent_ncb = 0;
        fimarkup.dealer_ncb = 0;
        fimarkup.packID = 0;
      }
      this.allFIMarkup.push(copiedObjects);
      this.FIMarkupProductsSelected = [];
    },
    onFilesSelected(e) {
      this.files = e.target.files;
    },
    selectFiles() {
      this.$refs.fileInput.click();
    },
    stateSelected(stateType) {
      if (stateType === 'agent') {
        if (this.agentState && this.agentState.length >= 2) {
          this.agentStateError = false;
        }
      }
      if (stateType === 'dealer') {
        if (this.dealerState && this.dealerState.length >= 2) {
          this.dealerStateError = false;
        }
      }
    },
    async uploadFile() {
      if (this.files.length < 1) {
        return;
      }
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files', this.files[i]);
      }
      formData.append('dealer_name', this.dealerName);

      let response = null;
      try {
        response = await filesAPIHandler.uploadFiles(this.dealerName, formData, this.$router);
        const self = this;
        this.success_txt = 'Upload of file successful';
        this.openModal('success');
        this.spinning = false;
        setTimeout(function () {
          self.modals['success'] = false; // Use your variable name
        }, 3000); // Hide after 5 secs
      } catch (e) {
        const self = this;
        this.error_txt = 'Error Uploading the files: ' + e;
        this.openModal('error');
        this.spinning = false;
        setTimeout(function () {
          self.modals['error'] = false; // Use your variable name
        }, 3000); // Hide after 5 secs
      }
    },
    uploadFiles(files) {
      if (!files.length) return;

      // Handle your file uploads here.
      // You might use a service like axios to post your files to a server.
    },
    setCurrentStep(current_step) {
      if (current_step === 'PUC') {
        this.initViewUsers();
        this.currentStep = current_step;
      } else {
        this.currentStep = current_step;
      }
    },
    saveDocusign(i) {
      const user = {
        first: i.userFirst,
        last: i.userLast,
        email: i.userEmail,
        paymentMethod: i.paymentMethod
      }
      this.docusignUsers.push(user);

    },
    removeDocusignUser(i) {
      let del = this.docusignUsers.findIndex(x => x.first === i.first && x.last === i.last && x.email === i.email);
      this.docusignUsers.splice(del, 1);

    },
    initViewUsers() {
      this.viewUsers = [];
      if (this.dealerFirstName && this.dealerLastName && this.dealerEmail) {
        this.viewUsers.push({
          first: this.dealerFirstName,
          last: this.dealerLastName,
          email: this.dealerEmail,
        });
      }
      this.viewUsers = this.viewUsers.concat(this.docusignUsers);
    },
    async addUser() {
      // Simple validation
      let isValid = await this.$refs.PUC.validate();
      if (!this.newUser.user_role) {
        this.newUserRoleError = true;
        isValid = false;
      }

      if (isValid) {
        this.users.push({ ...this.newUser });
        this.newUser = {
          password: null,
          email: null,
          first_name: null,
          last_name: null,
          user_role: null,
        };
      }
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },
    async finishPinnacleStep() {
      // save everything to the DB
      // gather the data into a big chunk to save it out

      const lenders = this.lenders
        .filter((name) => name.trim() !== '' )
        .map((name) => ( { name: name } ));

      const menu_options = {
        menu_options: this.selectedOptions
      };
      const pcrs_users = {
        pcrs_users: this.users,
      };
      const docusign_users = {
        docusign_users: this.docusignUsers,
      };
      const product_type = {
        product_type: 'pinnacle',
      };
      const pinnacle_products = {
        pinnacle_products: this.pinnacleProductsSelected,
      };
      const soft_packs = {
        soft_packs: this.allSoftPacks,
      };
      const fi_markup = {
        fi_markup: this.allFIMarkup,
      };
      const hard_packs = {
        hard_packs: this.allHardPacks,
      };
      const ncb = {
        ncb: this.dealerNCBProductsSelected,
      };
      const dealer = {
        session_id: this.session_id,
        first_name: this.dealerFirstName,
        last_name: this.dealerLastName,
        phone: this.dealerPhone,
        email: this.dealerEmail,
        dealer_name: this.dealerName,
        address1: this.dealerAddress1,
        address2: this.dealerAddress2,
        city: this.dealerCity,
        state: this.dealerState,
        zip: this.dealerZip,
        monthly_remit_first: this.monthlyRemitFirst,
        monthly_remit_last: this.monthlyRemitLast,
        payment_remit_first: this.monthlyRemitFirst,
        payment_remit_last: this.monthlyRemitLast,
        payment_remit_email: this.paymentRemitEmail,
        payment_remit_method: this.paymentRemitMethod,
        has_repair_center: this.haveOnSiteRepairCenter,
        has_preferred_repair_center: this.havePreferredRepair,
        preferred_repair_center: this.preferredRepairCenter,
        is_pinnacle: true,
        is_elevate: false,
        car_care: this.needCarCare,
        spp: this.needSPP,
        line5: this.needLine5,
      };
      const agent = {
        session_id: this.session_id,
        name: this.agentName,
        agency: this.agency,
        address1: this.agentAddress1,
        address2: this.agentAddress2,
        city: this.agentCity,
        state: this.agentState,
        zip: this.agentZip,
        agent_email: this.agentEmail,
      };
      const pinnacleItems = {
        agent,
        dealer,
        menu_options,
        pcrs_users,
        docusign_users,
        ncb,
        hard_packs,
        soft_packs,
        fi_markup,
        pinnacle_products,
        product_type,
        lenders,
      };
      let submitted = false;
      try {
        submitted = await productsAPIService.createProductSet(pinnacleItems, this.$router);
      } catch (e) {
        //console.log('Error: ', JSON.stringify(e));
      }
      this.currentStep = 'PNF';
    },
    async warrantyClaimGuide() {
      let response = await filesAPIHandler.getWarrantyClaimGuide(this.$router);
      //console.log(response);
      let url = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('download', 'WarrantyClaimGuide');
      document.body.appendChild(fileLink);
      fileLink.click();
      window.open(url, "_blank");
    },
    async contractProcessingGuide() {
      let response = await filesAPIHandler.getContractProcessingGuide(this.$router);
      let url = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('download', 'ContractProcessingGuide');
      document.body.appendChild(fileLink);
      fileLink.click();
      window.open(url, "_blank");
    },
    async contractCancelingGuide() {
      let response = await filesAPIHandler.getContractCancelingGuide(this.$router);
      let url = window.URL.createObjectURL(response.data);
      let fileLink = document.createElement('a');

      fileLink.href = url;
      fileLink.setAttribute('download', 'ContractCancelingGuide');
      document.body.appendChild(fileLink);
      fileLink.click();
      window.open(url, "_blank");
    },
    async nextStep() {
      let isValid = false;
      if (this.currentStep === 'AIN') {
        isValid = await this.$refs.AIN.validate();
        if (!this.agentState || this.agentState.length <= 1) {
          this.agentStateError = true;
          isValid = false;
        } else {
          this.agentStateError = false;
        }
      } else if (this.currentStep === 'DIN') {
        isValid = await this.$refs.DIN.validate();
        if (!this.dealerState && this.dealerState.length <= 1) {
          this.dealerStateError = true;
          isValid = false;
        }

      } else if (this.currentStep === 'PRC') {
        isValid = true;
      } else if (this.currentStep === 'LEN') {
        isValid = true;
      } else if (this.currentStep === 'ASI') {
        isValid = await this.$refs.ASI.validate();
      } else if (this.currentStep === 'RIN') {
        isValid = await this.$refs.RIN.validate();
        if (!this.paymentRemitMethod) {
          this.paymentRemitMethodError = true;
          isValid = false;
        }
      } else if (this.currentStep === 'MIN') {
        isValid = await this.$refs.MIN.validate();
        if (!this.needMenuIntegration) {
          this.menuIntegrationError = true;
          isValid = false;
        }
      } else if (this.currentStep === 'SPP') {
        isValid = true;
      } else if (this.currentStep === 'PUC') {

        const usercount = this.users.length;

        // allow to proceed without any users
        isValid = true; //usercount > 0;
        // create the docusign Users
        this.initViewUsers();
      } else if (this.currentStep === 'ZOS') {
        this.spinning = true;
        for (const user of this.viewUsers) {
          if (this.paymentRemitMethod === 'ach') {
            await this.dealerOnBoardACH(user);
          }
          await this.dealerOnBoardW9(user);
          await this.dealerOnBoardDoc(user);
        }
        this.spinning = false;
        isValid = await this.$refs.ZOS.validate();
      } else if (this.currentStep === 'EWA') {
        isValid = await this.$refs.EWA.validate();
      } else if (this.currentStep === 'PPS') {
        isValid = true;
      } else if (this.currentStep === 'APEXPS') {
        isValid = true;
      } else if (this.currentStep === 'AMPPS') {
        isValid = true;
      } else if (this.currentStep === 'PCM') {
        isValid = await this.$refs.PCM.validate();
      } else if (this.currentStep === 'HPK') {
        this.spinning = true;
        for (const user of this.docusignUsers) {
          if (user.paymentMethod === 'ach') {
            await this.dealerOnBoardACH(user);
          }
          await this.dealerOnBoardW9(user);
          await this.dealerOnBoardDoc(user);
        }
        this.spinning = false;
        isValid = await this.$refs.HPK.validate();
      } else if (this.currentStep === 'SPK') {
        isValid = await this.$refs.SPK.validate();
      } else if (this.currentStep === 'DPP') {
        isValid = true;
      } else if (this.currentStep === 'FIM') {
        isValid = true;
      }
      if (isValid) {

        let stn = this.findStepIndex(this.currentStep);
        stn++;

        this.currentStep = this.steps[stn].sid;

        if (this.currentStep === 'PPS') {
          // special case to handle pinnacle to elevate case
          if (this.pinnacleOrElevate === 'elevate') {
            // go to the finish, print the message that they'll get emailed, email them
            this.currentStep = 'CNF';
          }
          // proceed to Pinnacle step 9
        }
        // Handle other logic for advancing steps here
        // Ensure you don't go beyond the number of steps
        // if (this.currentStep > 7) this.currentStep = 7;
      }
      if (this.currentStep === 'CNF' && this.pinnacleOrElevate === 'pinnacle') {
        this.currentStep = 'PNF'
      } else if (this.currentStep === 'CNF' && this.pinnacleOrElevate === 'elevate') {
        this.currentStep = 'ELF'
      }
    },
    openModal(name) {
      this.modals[name] = true
    },
    closeModal(name) {
      this.modals[name] = false
    },
    toggleSelectAll() {
      if (this.selectAll) {
        // Select all items
        this.selectedOptions = [];
        for (const option of this.options) {
          this.selectedOptions.push(option);
        }
      } else {
        // Deselect all items
        this.selectedOptions = [];
      }
    },
    previousStep() {

      let stn = this.findStepIndex(this.currentStep);
      stn--;
      this.setCurrentStep(this.steps[stn].sid);

    },
  }
}
</script>

<style scoped>
/* Button */
button {
  padding: 10px 20px;
  margin: 10px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

.user-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

#products {
  font-size: 16px; /* Increase font size for better readability */
  padding: 10px; /* Add some padding */
  border-radius: 5px; /* Round the corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.div-group {
  padding: 10px; /* Add some padding */
  border:1px solid #007bff;
  border-radius: 5px; /* Round the corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow for depth */
}

.user-item:last-child {
  border-bottom: none;
}

.sidebar {
  background-color: #007bff;
  color: white;
}

label {
  display: block;
  margin-bottom: 10px;
}

button {
  margin-top: 20px;
}

ul {
  padding: 0;
  list-style-type: none;
}

.sidebar li {
  margin-bottom: 10px;
  position: relative;
  color: white;
  z-index: 100;
}

.sidebar li:hover {
  cursor: pointer;
}

.sidebar .active {
  font-weight: bold;
}

.dropzone {
  padding: 20px;
  border: 2px dashed #ccc;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.dropzone.active-dropzone {
  background-color: #f0f0f0;
}

.item {
  border: 1px solid #000; /* or any other color */
  padding: 10px;
  margin-bottom: 10px; /* Optional: for spacing between items */
  background-color: #fff; /* Normal background color */
}

.reverse-color {
  background-color: #ddd; /* Reverse background color */
}

.trash-button {
  background-color:#007bff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  background-image: url('data:image/svg+xml,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 24 24%22 fill%3D%22white%22%3E%3Cpath d%3D%22M3 6h18v2H3V6zm2 3h14v13a2 2 0 01-2 2H7a2 2 0 01-2-2V9zm4 3v9h2v-9H9zm4 0v9h2v-9h-2zm2-11h-4V0h4v2zm5 0h-3v2h-8V2H4v2H2v2h20V4h-2z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px 24px;
  width: 40px;
  height: 40px;
}

.trash-button:hover {
  background-color: #0056b3; /* Darker red for hover effect */
}

</style>
